<template>
    <div class="CompileRun">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog :title="data_id == undefined ? '新增文章' : '编辑文章'" :visible.sync="yunyingtan" :closeOnClickModal='false' top="3vh" width="1100px" @close="closeDialog()">
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width:218px">
                            <span class="xing">* </span>
                            文章标题:
                        </div>
                        <el-input v-model="form.article_title" autocomplete="off" placeholder="请输入标题"></el-input>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            是否是转载:
                        </div>
                        <template>
                            <el-radio v-model="form.is_original" label="1">是</el-radio>
                            <el-radio v-model="form.is_original" label="2">不是</el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth" class="pitem">
                        <div class="kuai">
                            <span class="xing">* </span>
                            文章缩略图:
                        </div>
                        <el-upload class="upload-demo" ref='upload' :action="$api.uploadFile + '?token=' + token" list-type="picture-card" :on-success="onSuccessupload" :on-remove="handleRemoves">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            作者:
                        </div>
                        <el-select v-model="form.authors_id" placeholder="请选择" @change="onarticle">
                            <el-option v-for="item in authorList" :key="item.id" :label="item.authors_name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width:218px">
                            <span class="xing">* </span>
                            文章简介:
                        </div>
                        <el-input v-model="form.article_class" autocomplete="off" placeholder="请输入文章简介"></el-input>
                        <!-- <el-input type="textarea" :rows="4" placeholder="请输入自我介绍" v-model="form.authors_desc"> -->
                        <!-- </el-input> -->
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            文章状态:
                        </div>
                        <template>
                            <el-radio v-model="form.is_show" label="1">显示</el-radio>
                            <el-radio v-model="form.is_show" label="2">隐藏</el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            文章类型:
                        </div>
                        <template>
                            <el-radio v-model="form.title_type" label="1">头部按钮</el-radio>
                            <el-radio v-model="form.title_type" label="2">文章咨询</el-radio>
                            <el-radio v-model="form.title_type" label="3">咨询要点</el-radio>
                        </template>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            文章分类:
                        </div>
                        <el-select v-model="form.type_id" placeholder="请选择" @change="onarticle">
                            <el-option v-for="item in TypeList" :key="item.id" :label="item.type_name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width:218px">
                            <span class="xing">* </span>
                            SEO关键字(keyword):
                        </div>
                        <el-input v-model="form.seo_keyword" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai" style="width:218px">
                            <span class="xing">* </span>
                            SEO简介(description):
                        </div>
                        <el-input v-model="form.seo_description" autocomplete="off" placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            文章详情:
                        </div>
                        <!-- <Tinymce1 ref="Tinymce1" @getTreeData='onproblem_description' :contentData='form.desc' uniqueId='uniqueId1' input="input"></Tinymce1> -->
                        <Tinymce1 id="myedit" ref="editor" :value="deschtml"></Tinymce1>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <!-- <span class="xing">* </span>
                            文章详情: -->
                        </div>
                        <!-- <el-input type="textarea" :rows="5" placeholder="请输入正文" v-model="deschtml" @blur="ondeschtml"></el-input> -->
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="closeDialog" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Tinymce1 from './UEditor.vue'
export default {
    data() {
        return {
            token: JSON.parse(localStorage.getItem("TZ-USER")).admin_token,
            yunyingtan: false, //操控弹窗展示
            form: {
                //表单信息
                article_title: "", //文章标题
                is_original: "",//是否可以转载
                article_link: "", //文章缩略图
                article_class: "", //文章分类标签
                title_type: '1',//文章分类
                type_id: '',  //所属分类
                is_show: "1",  //文章状态
                authors_id: "", //作者
                desc: "", //富文本
                seo_keyword: '',
                seo_description: ""
            },
            deschtml: "", //富文本代码
            formLabelWidth: "140px",
            activeseo_keyword: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            // dialogImageUrl: "",
            dialogVisible: false,
            page: 1,
            inputD: "",
            localismList: [], //配音师方言标签
            fileList: [],
            TypeList: [],
            authorList: [],
            data_id: ""
        };
    },
    mounted() {

    },
    components: {
        Tinymce1
    },
    methods: {
        input() {
            console.log(this.$refs.editor.myValue)//获取富文本中的值
        },
        ondeschtml() {
            this.form.desc = this.deschtml
        },
        //问题描述取子组件传来的值
        onproblem_description(data) {
            console.log(data)
            this.form.desc = data
            this.deschtml = data
        },
        onarticle(val) {

        },
        // 获取文章分类
        getWebsiteTypeList() {
            this.$service.post(this.$api.getWebsiteTypeList, "", (res) => {
                if (res.code == "200") {
                    this.TypeList = res.data;
                }
            });
        },
        // 获取作者分类
        getAuthorsList() {
            this.$service.get(this.$api.getAuthorsList, "", (res) => {
                if (res.code == "200") {
                    this.authorList = res.data.data;
                }
            });
        },
        onyunyingtan(data) {
            this.getWebsiteTypeList()
            this.getAuthorsList()
            this.yunyingtan = true;
            this.data_id = data.id;
            console.log(this.data_id)
            if (data.id != undefined) {
                this.form.article_title = data.article_title;
                this.form.is_original = data.is_original;
                this.form.article_link = data.article_link;
                this.dialogImageUrl = data.article_link;
                this.form.article_class = data.article_class;
                this.form.title_type = data.title_type;
                this.form.type_id = data.type_id;
                this.form.is_show = data.is_show;
                this.form.authors_id = data.authors_id;
                this.form.desc = data.article_info.desc;
                this.form.seo_description = data.seo_description;
                this.form.seo_keyword = data.seo_keyword;
                this.form.id = data.id;
                this.deschtml = data.article_info.desc;

            }

        },
        onlocalismChange(val) {
            // this.localismList = val;
        },
        handleRemoves(file, fileList) {
            // this.dialogImageUrl = "";
        },
        // 关闭
        closeDialog() {
            this.form.is_original = "";
            this.form.article_title = "";
            this.form.article_link = "";
            this.form.article_class = "";
            this.form.title_type = "1";
            this.form.type_id = "";
            this.form.is_show = "1";
            this.form.authors_id = "";
            this.form.desc = "";
            this.form.seo_keyword = "";
            this.form.seo_description = "";
            this.deschtml = "";
            this.$refs.upload.clearFiles();
            this.yunyingtan = false;
        },
        // 提交编辑
        tijiao() {
            var param = this.form;
            param.desc = this.$refs.editor.myValue;
            if (param.article_title == "") {
                this.$message({ message: "请输入文章标题", type: "warning" });
                return;
            }
            if (param.article_link == "") {
                this.$message({ message: "请上传文章缩略图", type: "warning" });
                return;
            }
            if (param.authors_id == "") {
                this.$message({ message: "请选择作者", type: "warning" });
                return;
            }
            if (param.article_class == "") {
                this.$message({ message: "请输入文章简介", type: "warning" });
                return;
            }
            if (param.type_id == "") {
                this.$message({ message: "请选择文章分类", type: "warning" });
                return;
            }
            if (param.seo_keyword == "") {
                this.$message({ message: "请输入关键字", type: "warning" });
                return;
            }
            if (param.seo_description == "") {
                this.$message({ message: "请输入SEO简介", type: "warning" });
                return;
            }
            if (param.desc == "") {
                this.$message({ message: "请输入文章详情", type: "warning" });
                return;
            }
            if (this.data_id == "") {
                param.id = "";
            }
            this.$service.post(this.$api.articleDetailEdit, param, (res) => {
                if (res.code == "200") {
                    this.$message({
                        message: "新增成功",
                        type: "success",
                    });
                    this.closeDialog();
                    this.$parent.ongetTopAgentAccountList();
                }
            });
        },

        //上传文件移出
        onRemoveupload(file, fileList) {
            // console.log("上传文件移出", fileList);
            this.fileListUpload = fileList;
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //头像上传成功
        onSuccessupload(res, file, fileList) {
            console.log(res)
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.form.article_link = res.data.file_url;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload(file, fileList) {
            // if (fileList.length > 1) {
            // 	fileList.splice(1, 1);
            // }
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },

        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
    },
};
</script>

<style lang="scss" scoped>
.CompileRun {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 180px;
        display: inline-block;
        text-align: center;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    .img {
        width: 148px;
        height: 148px;
        margin-right: 20px;
    }
    //
}
</style>
<style lang="scss">
.CompileRun {
    .upload-demo {
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-radio {
        line-height: 40px;
    }
}
</style>