<template>
    <div class="author">
        <!--  -->
        <div class="head padd20 borbut20">
            <div class="topSearchSou">

                <div class="searchdan">
                    <el-select v-model="title_type" style="margin-right:25px !important">
                        <el-option v-for="item in title_type_list" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input v-model="article_title" placeholder="请输入文章名称" style="margin-right:25px;"></el-input>
                    <!-- <el-input v-model="article_title" placeholder="请输入文章名称" style="margin-right:25px;"></el-input> -->
                    <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <!-- <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button> -->
            </div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">文章列表</div>
                <el-button type="primary" @click="addbud($event)" class="yinyinga" icon="el-icon-plus">新增文章</el-button>
                <div class="lvtotal" style="line-height: 33px;">总条数：{{lvtotal}} 条
                    <span style="margin-left:20px">每页显示:</span>
                    <el-select v-model="per_page" placeholder="10" @change="selectTrigger()" style="width:80px; margin:0px 15px">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    条
                </div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID" width="60px">
                </el-table-column>
                <el-table-column prop="article_link" label="头像" width="100px">
                    <template slot-scope="scope">
                        <img :src="scope.row.article_link?scope.row.article_link:''" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="article_title" label="文章标题">
                </el-table-column>
                <el-table-column prop="article_class" label="文章标签" width="300">
                    <template slot-scope="scope">
                        {{scope.row.article_class ? scope.row.article_class : '暂无'}}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="修改时间">
                </el-table-column>
                <el-table-column prop="is_operating_type" label="文章状态" width="100px">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.is_show==1" style="background: #07D258; padding:8px;border-radius: 5px;">{{scope.row.is_show==1 ? '显示 ' : scope.row.is_show==2 ? '隐藏':''}}</span>
                                <span v-if="scope.row.is_show==2" style="background: #0075F7;  padding:8px;border-radius: 5px;">{{scope.row.is_show==1 ? '显示 ' : scope.row.is_show==2 ? '隐藏':''}}</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='star(1,scope.row)'><span>显示</span></el-dropdown-item>
                                <el-dropdown-item @click.native='star(2,scope.row)'><span>隐藏</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="old" label="文章类型" width="120px">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <div class="el-dropdown-link">
                                <span v-if="scope.row.title_type==1" style="background: #07D258; padding:8px;border-radius: 5px;">头部按钮</span>
                                <span v-if="scope.row.title_type==2" style="background: #e6a23c;  padding:8px;border-radius: 5px;">文章咨询</span>
                                <span v-if="scope.row.title_type==3" style="background: #0075F7;  padding:8px;border-radius: 5px;">咨询要点</span>
                            </div>
                            <el-dropdown-menu slot="dropdown" placement="bottom" trigger="click" popper-class="class-menu">
                                <el-dropdown-item @click.native='typestar(1,scope.row)'><span>头部按钮</span></el-dropdown-item>
                                <el-dropdown-item @click.native='typestar(2,scope.row)'><span>文章咨询</span></el-dropdown-item>
                                <el-dropdown-item @click.native='typestar(3,scope.row)'><span>咨询要点</span></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column prop="old" label="文章分类" width="140px">
                    <template slot-scope="scope">
                        <div>
                            <el-select v-model="scope.row.type_id" placeholder="请选择" @change="onarticle(scope.row.type_id,scope.row.id)">
                                <el-option v-for="item in TypeList" :key="item.id" :label="item.type_name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="sorf" label="排序" width="80px">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.sort" placeholder="请输入内容" @change="oninput(scope.row.sort,scope.row)"> </el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="comments_count" label="文章评论数" width="100px">
                </el-table-column>
                <el-table-column prop="read_count" label="文章阅读数" width="100px">
                </el-table-column>
                <el-table-column prop="line_number" label="行数" width="60px">
                </el-table-column>
                <el-table-column prop="words_count" label="字数" width="60px">
                </el-table-column>

                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" plain @click="handleEdit(scope.$index, scope.row ,$event) " id="ComAccount">编辑</el-button>
                        <!-- <el-button type="primary" class="bianji" size="mini" plain @click="onCase(scope.$index, scope.row ,$event) " style="margin-left:20px" id="ComAccount">查看案例</el-button> -->
                        <el-popconfirm title="确定要删除本条记录吗" @confirm="onEnter(scope.row)">
                            <el-button size="mini" slot="reference" type="danger">删除</el-button>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next, jumper" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
            <AddAuthor ref="AddAuthor"></AddAuthor>
            <EditAuthor ref="EditAuthor"></EditAuthor>
            <!-- 
            <EditTeacher ref="EditTeacher"></EditTeacher>
            <Case ref="Case"></Case> -->
            <!-- <Tinymce1 ref="Tinymce1"></Tinymce1> -->

        </div>
    </div>
</template>
 
<script>
import AddAuthor from "./AddAuthor.vue";
import EditAuthor from "./EditAuthor.vue";
// import Tinymce1 from './UEditor.vue'

// import Case from "./case.vue";
// import EditTeacher from "./EditTeacher.vue";
export default {
    data() {
        return {
            paramData: {}, //表格参数
            dialogFormVisible: false,
            voice: "", //搜索内容
            tableData: [],
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1, //表格当前第几页
            page: "1",
            activeName: "",
            PageTopCompanyList: 1, //群页码
            company_id: "", //群id
            qunlist: "", //群循环
            PageTopCorporateAccountList: 1, //企业页码
            corporate_account_id: "", //企业id
            qiyelist: "", //企业循环
            accountlist: "", //端口列表
            PagegetAdvertiserList: "", //端口页码
            teacher_id: "",
            audio: "",
            options: [
                {
                    value: 25,
                    label: "25",
                },
                {
                    value: 50,
                    label: "50",
                },
                {
                    value: 100,
                    label: "100",
                },
            ],
            title_type: "",
            title_type_list: [
                {
                    value: "",
                    label: "文章类型",
                },
                {
                    value: 1,
                    label: "头部按钮",
                },
                {
                    value: 2,
                    label: "文章咨询",
                },
                {
                    value: 3,
                    label: "咨询要点",
                },
            ],
            date: "",
            search_start_date: "",
            search_end_date: "",
            TypeList: [],
            article_title: "",
            switch: ""
        };
    },
    mounted() {
        this.ongetTopAgentAccountList();
        this.getWebsiteTypeList()
    },
    components: {
        AddAuthor,
        EditAuthor,
    },
    methods: {
        typestar(val, row) {
            var param = {
                title_type: val,
                id: row.id,
            };
            this.$service.post(this.$api.setWebsiteArticleTitleType, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        onarticle(val, id) {
            var param = {
                type_id: val,
                id: id,
            };
            this.$service.post(this.$api.setWebsiteArticleType, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 获取文章分类
        getWebsiteTypeList() {
            this.$service.post(this.$api.getWebsiteTypeList, "", (res) => {
                if (res.code == "200") {
                    this.TypeList = res.data;
                }
            });
        },
        //资历
        qualifications(val, row) {
            var param = {
                old: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerOld, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 播放录音
        onrecord(row) {
            this.audio = row.file_introduce.file_url;
            this.dialogFormVisible = true;
        },
        closeDialog() {
            this.audio = "";
            this.dialogFormVisible = false;
        },
        //搜索
        onButtonInquire() {
            if (this.date != "") {
                this.search_start_date = this.dateFtt("yyyy-MM-dd", this.date[0]) ? this.dateFtt("yyyy-MM-dd", this.date[0]) : "";
                this.search_end_date = this.dateFtt("yyyy-MM-dd", this.date[1]) ? this.dateFtt("yyyy-MM-dd", this.date[1]) : "";
            }
            this.paramData = {
                search_start_date: this.search_start_date,
                search_end_date: this.search_end_date,
                article_title: this.article_title,
                title_type: this.title_type
            };
            this.paramPage = 1;
            this.ongetTopAgentAccountList();
        },
        //排序
        oninput(val, row) {
            var param = {
                id: row.id,
                sort: val,
            };
            this.$service.post(this.$api.setWebsiteArticleSort, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        //新增配音师
        addbud(row) {
            this.$refs.AddAuthor.onyunyingtan(row);
        },
        //查看案例
        onCase(index, row, e) {
            this.$router.push({
                path: "/ErLangCha/teacher/case",
                query: { id: row.id },
            });
            // this.$refs.Case.ontickling(row.id);
        },
        //配音师列表
        ongetTopAgentAccountList() {
            this.tableData = [];
            var param = this.paramData;
            param.page = this.paramPage;
            this.$service.get(this.$api.getWebsiteArticleList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        //显示条数改变
        selectTrigger() {
            this.paramPage = 1;
            this.paramData.per_page = this.per_page;
            this.ongetTopAgentAccountList();
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val;
            this.ongetTopAgentAccountList();
        },
        //设置文章显示
        star(val, row) {
            var param = {
                is_show: val,
                id: row.id,
            };
            this.$service.post(this.$api.setWebsiteArticleIsShow, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.$refs.AddAuthor.onyunyingtan(row);
            // this.$refs.EditAuthor.onbianjishi(row);

        },
        //删除
        onEnter(row) {
            var param = {
                id: row.id,
            };
            this.$service.post(this.$api.articleDel, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        //启用
        onEnable(val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
        //禁用
        onForbidden(val, row) {
            var param = {
                status: val,
                mixer_id: row.id,
            };
            this.$service.post(this.$api.setMixerStatus, param, (res) => {
                if (res.code == "200") {
                    this.ongetTopAgentAccountList();
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.author {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        img {
            width: 40px;
            height: 40px;
        }
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
    .ii {
        width: 20px;
        height: 20px;
    }
    .iconfont {
        // color: red;
        // margin-top: 24px;
    }
    .red {
        color: red;
    }
    .dialog {
        margin-top: 200px;
    }
    .audio {
        height: 100px;
        padding: 40px 0px;
    }
    .sorfinput {
    }
}
</style>
<style lang="scss">
.author {
    // .el-input {
    //     width: 180px;
    //     margin-right: 15px;
    // }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #fff;
        padding: 5px;
        position: relative;
    }
    .tag {
        cursor: pointer;
    }
    .el-dialog__header {
        background: #0075f7 !important;
    }
    .el-dialog__title {
        color: #fff;
    }
    .is-in-pagination {
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
        text-align: center !important;
    }
    .el-pagination__jump .el-input__inner {
        text-align: center !important;
    }
    .el-date-editor .el-range-separator {
        width: 10% !important;
    }
}
</style>